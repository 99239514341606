import * as React from "react"
import styled from "styled-components"
import { grey } from "../../constants/colors"
import ColorWrapper from "../../components/landing-page/ColorWrapper"
import Part from "../../components/landing-page/Part"
import Title from "../../components/landing-page/Title"
import { screenSizes } from "social-supermarket-model"

const AsSeenWrapper = styled.div<{
  padding?: string
}>`
  max-width: 1260px;
  margin: 0 auto;
  padding: ${({ padding }) => padding || "30px"};
`

const BrandLogosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;

  @media (max-width: ${screenSizes.smallMobile}px) {
    flex-wrap: wrap;
  }

  @media (max-width: 410px) {
    justify-content: center;
  }
`

const BrandLogo = styled.img`
  width: 100%;
  min-width: 100px;
  margin-right: 2%;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${screenSizes.smallMobile}px) {
    max-width: 160px;

    &:nth-child(2) {
      margin-right: 0;
      margin-bottom: 2%;
    }

    &:nth-child(1) {
      margin-bottom: 2%;
    }
  }
  @media (max-width: 410px) {
    max-width: 200px;
    margin-bottom: 2%;
    margin-right: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`

interface SeenBlockProps {
  padding?: string
  data: {
    title: string
    brandLogos: any
  }
}

const AsSeenBlock = ({ data, padding }: SeenBlockProps) => (
  <ColorWrapper background={grey}>
    <AsSeenWrapper padding={padding}>
      <Part width="650px" padding="30px 0 80px 60px" wrapPadding="40px 0 60px 60px">
        <Title>{data.title}</Title>
      </Part>
      <BrandLogosWrapper>
        {data.brandLogos.map(({ image, url }) => (
          <a href={url} target="_blank">
            <BrandLogo src={image} alt={image} key={url} />
          </a>
        ))}
      </BrandLogosWrapper>
    </AsSeenWrapper>
  </ColorWrapper>
)

export default AsSeenBlock
