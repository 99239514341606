import * as React from "react"
import styled from "styled-components"
import FirstNameField from "./fields/FirstNameField"
import LastNameField from "./fields/LastNameField"
import EmailField from "./fields/EmailField"
import PhoneField from "./fields/PhoneField"
import MessageField from "./fields/MessageField"
import HearAboutUsField from "./fields/HearAboutUsField"
import CompanySizeField from "./fields/CompanySizeField"
import CompanyField from "./fields/CompanyField"
import { PrimaryButton, Spinner } from "social-supermarket-components"
import { colors, screenSizes } from "social-supermarket-model"
import { gillFont } from "../../../../../../constants/fonts"
import { enquiry } from "../../../../../../api/leadCaptureApi"
import * as tracker from "../../../../../../tracking/tracker"

export const DEFAULT_ENQUIRY_FORM_STATE: EnquiryFormStateType = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  message: "",
  hearAboutUs: "",
  company: "",
  companySize: "1-50",
}

const Container = styled.div<{ margin?: string }>`
  margin: ${({ margin }) => margin || 0};
`
const SuccessMessage = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Left = styled.div`
  flex: 1;
  margin: 0 5px 0 0;
  @media (max-width: ${screenSizes.smallMobile}px) {
    min-width: 100%;
    margin-right: 0;
  }
`

const StyledButton = styled(PrimaryButton)`
  &&& {
  }
`

const Right = styled.div`
  flex: 1;
  margin: 0 0 0 5px;

  @media (max-width: ${screenSizes.smallMobile}px) {
    min-width: 100%;
    margin-left: 0;
  }
`

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
`

const ErrorMessage = styled.span`
  color: ${colors.rouge};
  font-size: 18px;
  line-height: 32px;
  font-family: ${gillFont};
  margin-left: 15px;
`

export interface EnquiryFormStateType {
  firstName: string
  lastName: string
  email: string
  phone: string
  company: string
  message: string
  hearAboutUs: string
  companySize: string
}

interface Props {
  onBlur?: (name: string) => void
  source: "Inbound Enquiry" | "Inbound Enquiry - Gifting" | "Inbound Enquiry - General"
  name: string
  isMql?: boolean
  to?: string
}

const EnquiryForm = ({
  onBlur,
  source = "Inbound Enquiry",
  name,
  isMql = true,
  to = "info@socialsupermarket.org",
}: Props) => {
  const [isValid, setIsValid] = React.useState<boolean>(true)
  const [enquiryFormState, setEnquiryFormState] = React.useState<EnquiryFormStateType>(
    DEFAULT_ENQUIRY_FORM_STATE
  )
  const [validate, setValidate] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isSuccessful, setIsSuccessful] = React.useState<boolean>(false)

  const handleSubmit = async () => {
    event.preventDefault()
    const isValid = EnquiryForm.isValid(enquiryFormState, isMql)
    if (isValid) {
      setIsLoading(true)
      await enquiry(enquiryFormState, source, isMql, to)
      setIsLoading(false)
      setIsSuccessful(true)
      const value = getValue(enquiryFormState.companySize, enquiryFormState.email)
      tracker.corporateEnquiry(value)
    } else {
      setIsValid(false)
      setValidate(true)
    }
  }

  const handleOnChange = (name, value) => setEnquiryFormState(prev => ({ ...prev, [name]: value }))

  return (
    <Container>
      <Spinner isLoading={isLoading} />
      {!isSuccessful && (
        <form id={name} onSubmit={handleSubmit}>
          <Form>
            <Row>
              <Left>
                <FirstNameField
                  onChange={handleOnChange}
                  onBlur={onBlur}
                  state={enquiryFormState}
                  validate={validate}
                />
              </Left>
              <Right>
                <LastNameField
                  onChange={handleOnChange}
                  onBlur={onBlur}
                  state={enquiryFormState}
                  validate={validate}
                />
              </Right>
            </Row>
            <Row>
              <Left>
                <EmailField
                  onChange={handleOnChange}
                  onBlur={onBlur}
                  state={enquiryFormState}
                  validate={validate}
                />
              </Left>
              <Right>
                <PhoneField
                  onChange={handleOnChange}
                  onBlur={onBlur}
                  state={enquiryFormState}
                  validate={validate}
                  optional
                />
              </Right>
            </Row>
            {isMql && (
              <CompanyField
                onChange={handleOnChange}
                onBlur={onBlur}
                state={enquiryFormState}
                validate={validate}
              />
            )}
            <MessageField onChange={handleOnChange} state={enquiryFormState} />
            {isMql && (
              <HearAboutUsField
                validate={validate}
                state={enquiryFormState}
                onChange={handleOnChange}
              />
            )}
            {isMql && (
              <CompanySizeField
                validate={validate}
                state={enquiryFormState}
                onChange={handleOnChange}
              />
            )}
            <StyledButton>Submit</StyledButton>
            {!isValid && (
              <ErrorContainer>
                <ErrorMessage>Something is wrong. Please try again.</ErrorMessage>
              </ErrorContainer>
            )}
          </Form>
        </form>
      )}
      {isSuccessful && <SuccessMessage>Thank you for your interest.</SuccessMessage>}
    </Container>
  )
}

const valueMap = { "1-50": 5, "51-99": 20, "100-199": 50, "200-1000": 75, "1000+": 100 }

const getValue = (companySize: string, email: string): number => {
  let v = valueMap[companySize]
  if (isNaN(v)) {
    v = 0
  }
  if (isPersonalEmail(email)) {
    v = v / 10
  }
  return v
}

const isPersonalEmail = (email: string): boolean => {
  return (
    email.includes("gmail") ||
    email.includes("hotmail") ||
    email.includes("yahoo") ||
    email.includes("outlook") ||
    email.includes("live.com")
  )
}

const fields = [FirstNameField, LastNameField, EmailField, MessageField]

const mqlFields = [
  FirstNameField,
  LastNameField,
  EmailField,
  MessageField,
  HearAboutUsField,
  CompanySizeField,
]

EnquiryForm.isValid = (state: EnquiryFormStateType, isMql: boolean) => {
  if (isMql) {
    return mqlFields.every(Field => Field.isValid(state))
  } else {
    return fields.every(Field => Field.isValid(state))
  }
}

export default EnquiryForm
