import * as React from "react"
import { validation } from "social-supermarket-model"
import FormFieldProps from "../../../../../../form/FormFieldProps"
import OutlinedInput from "../../../../../../form/OutlinedInput"
import { EnquiryFormStateType } from "../EnquiryForm"

const PhoneField = ({
  onChange,
  onBlur,
  state,
  validate,
  optional,
}: FormFieldProps<EnquiryFormStateType>) => {
  return (
    <OutlinedInput
      name="phone"
      onChange={onChange}
      value={state.phone}
      label="Phone"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={PhoneField.isValid(state)}
      optional={optional}
    />
  )
}

PhoneField.isValid = ({ phone }) => {
  return phone && validation.validPhoneNumberFormat.test(phone)
}

export default PhoneField
